import { Injectable } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppConstants } from '../common/variable';
import { startsWith, get } from 'lodash';
// import { ErrorDialogService } from './error-dialog-service.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor() {
    // public errorDialogService: ErrorDialogService
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('token');
    if (!startsWith(request.url, AppConstants.Services.ApiService + '/api/login')
      // && !request.url.toString().includes(".json")
    ) {
      if (get(AppConstants.User, 'Token')) {
        request = request.clone({
          headers: new HttpHeaders({
            // 'Content-Type': 'multipart/form-data',
            'token': AppConstants.User.Token,
            'campaignId': AppConstants.App.campaignId,
            'companyId': AppConstants.App.companyId,
            'roleId': AppConstants.App.roleId
          })
        });
        // request.headers.set('token', AppConstants.User.token)
      }

    }

    let isUpload;
    if (request.url.indexOf('upload') > 1 || request.url.indexOf('kml') > 1 || request.url.indexOf('thread/message/doktar') > 1 || request.url.indexOf('api/cropmap/save/report') > 1) {
      isUpload = true;
    } else {
      isUpload = false;
    }
    if (!request.url.toString().includes(".json") && !request.url.toString().includes('api/portal/bulk/import/user') && !request.url.toString().includes('/v2/api/portal/create/news')  ) {
      if (!isUpload) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      }
      // if (!request.headers.has('Content-Type')) {
      //   request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      // }
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    }

    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // console.log('event--->>>', event);
        // this.errorDialogService.openDialog(event);
      }
      return event;
    }), catchError((error: HttpErrorResponse) => {
      let data = {};
      data = {
        reason: error && error.error && error.error.reason ? error.error.reason : '',
        status: error.status
      };
      // this.errorDialogService.openDialog(data);
      return throwError(error);
    }));
  }
}
